import './sections.css'

export default function Coding () { return (
    <div id="section2">
        <div id="textbox">
            <br/><div id="title">CODING</div>
            <table>
                <tr><br/></tr>
                <tr><a id ="text" href="https://scratch.mit.edu/">Scratch</a></tr>
                <tr><br/></tr>
                <tr><a id ="text" href="https://www.python.org/">Python</a></tr>
                <tr><br/></tr>
                <tr><a id ="text" href="https://www.w3schools.com/">HTML/CSS/Javascript</a></tr>
                <tr><br/></tr>
            </table>
        </div>
    </div>
)}